import React, { Component } from 'react';
import ModalVideo from 'react-modal-video';
/* eslint-disable */

const bookLink = 'https://polandbylocals.com/tour/cycling-round-the-gdansk-old-town/';

class Video extends Component {

	constructor() {
		super()
		this.state = {
			isOpen: false
		}
		this.openModal = this.openModal.bind(this)
	}

	openModal() {
		this.setState({ isOpen: true })
	}

	render() {
		return (
			<div>
				<ModalVideo channel='vimeo' isOpen={this.state.isOpen} videoId='266082244' onClose={() => this.setState({ isOpen: false })} />
				<div className="buttons">
					<a className="button play-button" onClick={this.openModal}>PLAY VIDEO</a>
					<a className="button book-button" href={bookLink}>BOOK NOW</a>
				</div>
			</div>
		)
	}
}

export default Video;