import React from 'react';
import './style.scss';

import Navbar from './navbar';
import Video from './video';

const Header = () => (
	<section className="hero gradientBg is-fullheight">
		<Navbar />

		<div className="hero-body">
			<div className="container">
				<div className="columns is-vcentered">
					<div className="column">
						
					
						<h1 className="has-text-white is-size-1">
							Cycling Tours in Gdańsk
					</h1>
						<p className="has-text-white is-size-2">
							<b>Experience city everyday on two wheels.</b><br />
							Everyday from 20.04 - 30.09.2019
					</p>
						<p className="date"></p>
						<Video />

					</div>
				</div>
			</div>
		</div>
	</section >
);

export default Header;
