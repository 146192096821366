import React from 'react';
import './style.scss';
import Line from '../images/line.png';


const Midsection = () => (
	<div>
		<div className="container">
			<img className="line" src={Line}></img>
		</div>
		<section className="section">
			<div className="container">
				<div className="columns is-multiline">
					<div className="column is-one-third">
						<h3>
							About the trip:
						</h3>
						<p>
							Join everyday cycling tours in Gdansk and discover the pleasure of sightseeing. Every day, we cross Gdansk on bikes outside the beaten path. Cycling tours around Gdansk is the best option to get to know the city, its traditions and history. Our groups are small, up to 15 people, run by an experienced guide.
						</p>
					</div>
					<div className="column is-one-third">
						<h3>Route:</h3>
						<p>
							We have arranged the route of our daily cycling tours in order to show you the beauty of Gdansk as well as to tell chronologically about its history. The route of a cycling trip around Gdansk will not take us more than 3 hours. During this time, we will ride on two wheels through the Old Town in Gdansk, then we will drive over the Granary Island to the bottom of the Lower Town and the Bastions. At the end, we will visit the famous Gdansk Shipyard to listen to the proud history of the social movement, Solidarity.</p>
					</div>
					<div className="column is-one-third">
						<h3>About us:</h3>
						<p>
							Our guides are enthusiasts who know the city like hardly anyone. We are a group of local guides and licensed professionals who love Gdansk and our region. We will be happy to show you the most interesting places of the Main City, as well as discover the secret side of Gdansk. Forget about boring trips and go with us to a bicycle adventure!
							</p>
					</div>

				</div>
			</div>
		</section>
	</div>
);

export default Midsection;
